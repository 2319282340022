@import '/src/client/theme/consts.less';

:local(.facebookEmailModalContent) {
    padding: 0 0 15px 0;
}

:local(.facebookButton) {
    position: relative;
    font-family: inherit;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    border-radius: 3px;
    border-width: 0;
    height: 59px;
    padding: 18px 30px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #4267b2;

    i {
        font-size: 1.7em;
        position: absolute;
        left: 24px;
        bottom: 11px;
    }
}

:local(.googleButton) {
    position: relative;
    font-family: inherit;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    border-radius: 3px;
    border-width: 1px;
    border-color: #777;
    height: 59px;
    padding: 18px 30px;
    color: #444;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #fff;
    margin-top: 15px;

    img {
        position: absolute;
        left: 20px;
        bottom: 12px;
        height: 32px;
    }
}
