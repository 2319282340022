@import '/src/client/theme/consts.less';
@import '/src/client/theme/colors.less';

:local(.noMessageAdvertise) {
    width: 430px;
    max-width: 100%;
    margin: @baseline auto;
    @media @mobile {
        width: unset;
    }
}
