@import '/src/client/theme/consts.less';
@import '/src/client/theme/colors.less';
@import '/src/client/theme/common.less';

@searchPadding: 19px 15px;
@logoWidth    : 155px;
@logoPadding  : 30px;

@maxAspectRatio: 2.35;
@botDesktopMaxHeight: 1080px;
@botMobileMaxHeight: 840px;

:root {
    --title-font-size-var: @h-1FontSize;
    --title-margin-top-var: 15vh;
    --title-margin-bottom-var: 20vh;
    --line-counter: 1;
    --banner-line-counter: 1;

    .line-counter-2 {
        --line-counter: 2;
    }

    .line-counter-3 {
        --line-counter: 3;
    }

    .line-counter-4 {
        --line-counter: 4;
    }

    .banner-lines-2 {
        --banner-line-counter: 2;
    }

    .banner-lines-3 {
        --banner-line-counter: 3;
    }

    .banner-lines-4 {
        --banner-line-counter: 4;
    }
    @media not @superLarge {
        --title-margin-top-var: 10vh;
        --title-margin-bottom-var: 10vh;
    }
    @media @small, @tablet {
        --title-font-size-var: @h0FontSize;
    }
    @media @mobileLandscape {
        --title-font-size-var: @h1FontSize;
    }
    @media @smallHomeNavigation {
        --title-margin-top-var: 10vh;
        --title-margin-bottom-var: 10vh;
    }
    @media @mobile {
        --title-font-size-var: @h2FontSize;
        --title-margin-top-var: 4vh;
        --title-margin-bottom-var: 4vh;
    }
}

:local(.logoLink) {
    overflow: hidden;
}

:local(.logo) {
    width: 160px !important; // todo this important is important to override semanticUI
    height: @baseline * 3;
}

:local(.rightMenu) {
    justify-content: flex-end;
}

:local(.heroBackgroundImage),
:local(.navBackground) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: top;
}

:local(.heroBackgroundImage) {
    z-index: -1;
}
@media @smallHomeNavigation {
    :local(.heroContainer) {
        & > :local(.heroBackgroundImage) {
            clip: rect(0, auto, calc(@mobileNavHeightHome + 60px), 0);
        }
    }
}

/* Hide background when showing hero & morpher */
:local(.navBackground) ~ :local(.nav).ui.top.fixed.menu {
    &::before {
        display: none;
    }
}

:local(.navBackground) {
    clip: rect(0, auto, 166px, 0);
    @media @smallHomeNavigation, @mobileLandscape {
        clip: rect(0, auto, @navHeight, 0);
    }
}

:local(.nav).ui.top.fixed.menu,
:local(.heroContainer) {
    box-shadow: none;
    background-color: transparent;
    color: #fff;
    height: @navHeight;
}

:local(.nav).ui.top.fixed.menu {
    z-index: 106;
    border-bottom: none;

    &:local(.expanded) {
        @media @mobile {
            :local(.linksContainer) {
                display: none;
            }
        }
    }
    @media @mobile {
        &:local(.expanded)::before {
            clip: rect(0, auto, @navHeight, 0);
        }

        &:local(.fixedSearch)::before {
            clip: rect(0, auto, @mobileNavHeightHome, 0);
        }
    }

    &:not(:local(.expanded)) {
        @media @mobile, @mobileLandscape {
            padding-top: 0;
            height: @mobileNavHeight;
        }
    }

    &:local(.fixedSearch) {
        @media @mobile {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    a,
    a:hover,
    .dropdown {
        color: #fff;
        padding: @baseline;
    }

    > a:first-child {
        font-family: sans-serif;
        font-size: @h3FontSize;
        font-weight: bold;
        letter-spacing: 0.6px;
        background: none !important;
        @media @mobile, @mobileLandscape {
            width: auto;
        }
    }
}

:local(.heroContainer) {
    height: 100vh;
    max-height: @maxAspectRatio * 100vw;
    padding-top: calc(5vh + 28px);
    padding-bottom: 360px;
    margin: 0 auto;

    @heroWidth: 622px;
    @media @notSuperLarge {
        padding-top: 166px;
    }
    @media @mobileLandscape {
        padding-top: 30px;
    }
    @media @smallHomeNavigation {
        height: auto;
        padding-top: @navHeight;
        position: sticky;
        top: calc(-1 * (calc(var(--banner-line-counter) * @smallFontSize) + calc(var(--line-counter) * var(--title-font-size-var)) + var(--title-margin-bottom-var) + var(--title-margin-top-var) + 2 * @baseline));
        margin-bottom: ~"calc(min(100vh, @{maxAspectRatio} * 100vw) - 215px)"; // this margin botton logic is part of original logic, I don't know from where those values come from
        z-index: 52;
        padding-bottom: 20px;
    }

    h1 {
        font-size: var(--title-font-size-var);
        margin: var(--title-margin-top-var) auto var(--title-margin-bottom-var) auto;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fff;
        font-weight: 400;
        @media @smallHomeNavigation {
            width: auto;
            line-height: 1;
            letter-spacing: 0.3px;
            z-index: 104;
            position: relative;
        }
    }

    .ui.search {
        max-width: @heroWidth;
        max-height: 70px;
        display: flex;
        z-index: 106;
        margin: auto;
        @media @mobile {
            position: sticky;
            bottom: 0;
            width: auto;
        }

        .results {
            z-index: 0;
        }

        input {
            flex: 1 0 0;
            border-radius: 5px;
            background-color: #fff;
            padding: 25px;
            font-size: 17px;
            letter-spacing: 0.3px;
            border: 1px solid rgba(34, 36, 38, 0.15);
            z-index: 2;
            transition: all 0.02s linear, opacity 0s linear;
            @media @mobile {
                padding: @searchPadding !important;
            }
        }

        i.icon {
            z-index: 3;
            transition: none;
            right: 7px;
        }
    }
}

:local(.bot) {
    body::before {
        height: @botDesktopMaxHeight;
    }

    :local(.navBackground)::before {
        height: @botDesktopMaxHeight;
    }

    :local(.nav).ui.top.fixed.menu::before {
        height: @botDesktopMaxHeight;
    }

    :local(.heroContainer) {
        height: @botDesktopMaxHeight;
    }
    @media @mobile {
        body::before {
            height: @botMobileMaxHeight;
        }

        :local(.navBackground)::before {
            height: @botMobileMaxHeight;
        }

        :local(.nav).ui.top.fixed.menu::before {
            height: @botMobileMaxHeight;
        }

        :local(.heroContainer) {
            height: auto;
            margin-bottom: ~"calc(@{botMobileMaxHeight} - 330px)";
        }
    }
}

:local(.searchContainer) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    input {
        font-size: @h5FontSize;
        letter-spacing: 0.3px;
    }
    @media @mobileLandscape {
        max-width: 662px;
        padding: 2px 15px;
    }
    @media @mobile {
        justify-content: right;
        margin: 0;
        align-items: center;
        text-align: right;
        flex-direction: row;
        opacity: 0;
        display: none;

        input {
            border-radius: 5px;
            padding: @searchPadding !important;
            font-size: @h5FontSize;
            letter-spacing: 0.3px;
        }
    }

    &.hide {
        opacity: 0;
        @media @smallHomeNavigation {
            display: none;
        }
    }

    .ui.search {
        width: 100%;
        max-width: 662px;

        i.icon {
            z-index: 3;
            transition: none;
            right: 7px;
        }
    }
}

:local(.userIcon) {
    stroke: white;

    &:local(.suspended) {
        stroke: @dangerColor;
    }
}

.notifyAnimation(popup-notification-animation, #e5e5e5, false);

.ui.popup:local(.suspendedUserPopup) {
    &:local(.notification) {
        animation-name: popup-notification-animation;
        animation-duration: 2s;
    }
}

:local(.downButton) {
    position: absolute;
    bottom: 0;
    left: calc(50% - 75px);
}

:local(.divider) {
    border-bottom: 1px solid @dividerColor;
    margin: @baseline / 2 0;
}

:local(.dividerEmpty) {
    margin-top: @baseline / 2;
}

.ui.menu .ui.dropdown .menu > .item.item-override {
    padding: @baseline !important;
}

:local(.primaryBackground) {
    // it's important to override semantic
    background-color: @primaryNav !important;
}

:local(.searchBoxNavigationContainer) {
    margin: auto;
}

:local(.expandedSearchClass) {
    height: @navHeight + @searchBoxHeight;
    @media @mobileLandscape {
        height: auto;
    }
}

:local(.forcedVisible) {
    opacity: 1 !important;
}
