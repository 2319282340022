@import '/src/client/theme/consts.less';
@import '/src/client/theme/common.less';
@import '/src/client/theme/colors.less';

:local(.table) {
    position: relative;

    table.ui.table {
        border: none;
        position: relative;

        thead {
            tr {
                th,
                th.sorted {
                    vertical-align: bottom;
                    background: white;
                    border: none;
                    border-bottom: 1px solid @borderColor;
                    color: #a09da2;
                    font-weight: normal;
                    font-size: 12px;
                    padding: 10px;
                    transition: background-color 0.2s ease, color 0.2s ease, font-weight 0.2s ease;
                    position: sticky;
                    top: 145px;
                    z-index: 10;
                    @media @mobile,
                        @mobileLandscape,
                        @tablet {
                        top: 0;
                    }

                    &:hover {
                        background: #f7f7f7;
                    }

                    &.centered {
                        text-align: center;
                    }

                    &:local(.dimmer) {
                        padding: 0;
                    }
                }

                &:local(.snapFirstColumn) {
                    &:nth-child(3),
                    &:first-child {
                        th,
                        th.sorted {
                            &:first-child {
                                position: sticky;
                                left: 0;
                                z-index: 11;
                                box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.3);
                            }
                        }
                    }

                    &:nth-child(2) {
                        th,
                        th.sorted {
                            &:first-child {
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border: none;
                    font-size: 12px;
                    padding: 10px;

                    &.centered {
                        text-align: center;
                    }

                    &:local(.dimmer) {
                        padding: 0;
                    }
                }

                &:local(.snapFirstColumn) {
                    td:first-child {
                        background: white;
                        position: sticky;
                        left: 0;
                        z-index: 6;
                        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
                    }
                }

                &:local(.defaultRow) {
                    background: @zebraStripesPrimaryColor;

                    &:local(.snapFirstColumn) {
                        td:first-child {
                            background: @zebraStripesPrimaryColor;
                        }
                    }
                }

                &:local(.distinctRow) {
                    background: @zebraStripesSecondaryColor;

                    &:local(.snapFirstColumn) {
                        td:first-child {
                            background: @zebraStripesSecondaryColor;
                        }
                    }
                }

                &:local(.pinned) td {
                    animation: 'pinned' 1.5s;
                }
            }

            tr:not(:first-child) td {
                border-top: 1px solid @borderColor;
            }
        }
    }
}

.notifyAnimation(pinned, false, white, @actionColor);

:local(.tableWithScroll) {
    max-width: 100vw;
    overflow-x: auto;
}

:local(.tableWithGlobalScroll) {
    padding-top: 210px;
}

:local(.dimmer).dimmer.ui.active {
    transition: all 2.2s ease;
    z-index: 3;
    opacity: 0.8;
}

:local(.dimmer).dimmer.ui.dimmer > .content {
    position: fixed;
    left: calc(50% - 8px);
    top: calc(50% + 295px / 2);
    @media @mobile {
        top: calc(50% + 135px / 2);
    }
}

:local(.dimmerNoRows).dimmer.ui.dimmer > .content {
    top: calc(50% - 60px);
    @media @mobile {
        top: calc(50% - 200px);
    }
}

:local(.dimmerSmall).dimmer.ui.dimmer > .content {
    top: calc(50% - 20px);
    @media @mobile {
        top: calc(50% - 180px);
    }
}

:local(.fixedRow) {
    position: fixed;
    z-index: 10;
}

:local(.stickyRow) {
    position: sticky;
    z-index: 10;
}

:local(.message) {
    color: #746f77;
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

:local(.loadingRow) {
    position: relative;
    margin-top: 30px;
}
