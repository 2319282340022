:local(.treeDropdown) {
    .hide:not(.match-in-children) {
        display: none;
    }

    .dropdown {
        width: 100%;
        position: relative;
        display: table;
    }

    .dropdown .dropdown-trigger {
        max-height: 200px;
        overflow: auto;
        display: flex;
        width: 100%;
        padding: 2px;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        transition: box-shadow 0.1s ease, width 0.1s ease;
        justify-content: space-between;
        line-height: 1em;
    }

    .dropdown .dropdown-trigger.arrow {
        min-height: 38px;
        line-height: 1em;
        cursor: pointer;
    }

    .dropdown .dropdown-trigger:hover {
        border-color: rgba(34, 36, 38, 0.35);
    }

    .dropdown .dropdown-trigger.arrow::after {
        content: '';
        width: 8px;
        height: 12px;
        vertical-align: middle;
        color: #3c3c3c;
        margin-right: 10px;
        margin-top: 10px;
        min-width: 8px;
    }

    .dropdown .dropdown-trigger.arrow.bottom::after {
        background-image: url('img/expand-down.svg');
        vertical-align: middle;
        color: #3c3c3c;
    }

    .dropdown .dropdown-trigger.arrow.top::after {
        background-image: url('img/expand-down.svg');
        vertical-align: middle;
        color: #3c3c3c;
    }

    .dropdown .dropdown-trigger.arrow.disabled {
        cursor: not-allowed;
    }

    .dropdown .dropdown-trigger.arrow.disabled.bottom::after {
        color: #b9b9b9;
    }

    .dropdown .dropdown-content {
        width: 100%;
        position: absolute;
        padding: 4px;
        z-index: 1;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    }

    .dropdown .dropdown-content ul {
        margin: 0;
        padding: 0;
    }

    .tag {
        background-color: #f4f4f4;
        border: 1px solid #e9e9e9;
        border-radius: 2px;
        display: flex;
        padding: 4px;
    }

    .tag:focus-within {
        background-color: #e9e9e9;
        border-color: #a0a0a0;
    }

    .tag-remove {
        color: #a0a0a0;
        font-size: 75%;
        line-height: 100%;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
    }

    .tag-remove.disabled,
    .tag-remove.readOnly {
        cursor: not-allowed;
    }

    .tag-remove:focus {
        color: #3c3c3c;
    }

    .tag-list {
        display: inline;
        padding: 0;
        margin: 0;
    }

    .tag-item {
        display: inline-block;
        margin: 4px;
    }

    .tag-item .search {
        display: none;
        width: 100%;
        border: none;
        border-bottom: 1px solid #ccc;
        outline: none;
    }

    .tag-item:last-child {
        margin-right: 4px;
    }

    .checkbox-item,
    .radio-item {
        vertical-align: middle;
        margin: 0 4px 0 0;
    }

    .checkbox-item.simple-select,
    .radio-item.simple-select {
        display: none;
    }

    .infinite-scroll-component {
        max-height: 216px;
    }

    .toggle {
        white-space: pre;
        margin-right: 4px;
        cursor: pointer;
    }

    .searchModeOn .toggle {
        display: none;
    }

    .toggle::after {
        cursor: pointer;
        content: '';
        vertical-align: middle;
        color: #3c3c3c;
        padding-right: 8px;
        margin-top: 10px;
    }

    .toggle.collapsed::after {
        background-image: url('img/arrow-down.svg');
    }

    .toggle.expanded::after {
        background-image: url('img/arrow-up.svg');
    }

    .node {
        list-style: none;
        white-space: nowrap;
        padding: 4px;
    }

    .node.leaf.collapsed {
        display: none;
    }

    .node.disabled > * {
        color: gray;
        cursor: not-allowed;
    }

    .node.match-in-children.hide .node-label {
        opacity: 0.5;
    }

    .node.focused {
        background-color: #f4f4f4;
    }

    .node.tree {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .node.leaf {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .node > label {
        margin-left: 2px;
        display: inline-flex;
        cursor: pointer;
        position: relative;
    }

    .node > label > input {
        border: 1px solid #979797;
        border-radius: 3px;
        width: 16px;
        height: 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none;
        transition-duration: 0.3s;
        background-color: #fff;
        cursor: pointer;
    }

    .node > label > input:checked {
        border: 1px solid #979797;
        background-color: #979797;
    }

    .node > label > span::before {
        font-family: 'Checkbox', sans-serif;
        font-size: 9px;
        content: '';
        display: block;
        text-align: center;
        color: #fff;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: -1px;
    }

    .node > label > input:checked + span::before {
        content: '\e800';
    }

    .node > label > input:indeterminate + span::before {
        content: '\e801';
        color: #979797;
    }

    .node > label > input:active {
        border: 2px solid #979797;
    }
}
