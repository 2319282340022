// TODO this checkbox has to be rewritten - Kacper
@import '/src/client/theme/colors.less';

:local(.checkbox) {
    input:focus {
        outline: none;
    }

    &.ui.checkbox {
        input ~ label {
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #99969b;
            transition: color 0.2s ease;

            &:hover {
                color: darken(#99969b, 10%);
            }

            &::before {
                transition: background-color 0.2s ease, border 0.2s ease;
            }

            &::after {
                transition: background-color 0.2s ease, border 0.2s ease;
            }
        }

        &.checked input ~ label {
            color: #121212;

            &:hover {
                color: lighten(#121212, 5%) !important;
            }
        }
    }

    // normal checkbox
    &.ui.checkbox:not(.radio):not(.toggle) {
        input ~ label {
            &::before {
                border-color: #979797;
                border-radius: 3px;
                width: 16px;
                height: 16px;
            }

            &:hover::before {
                border-color: #797979;
                background-color: #f9f9f9;
            }
        }

        &.checked {
            input ~ label {
                &::after {
                    color: #99969b;
                    font-size: 9px;
                }
            }
        }

        &.disabled {
            input ~ label {
                opacity: 0.2;
            }
        }
    }

    // normal strong checkbox
    &.ui.checkbox:not(.radio):not(.toggle).strong {
        input ~ label {
            color: @primaryTextColor;
        }

        &.checked {
            input ~ label {
                &::before {
                    background-color: #756f78;
                    border: solid 1.5px #756f78;
                }

                &::after {
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
    }

    &.ui.toggle.checkbox.disabled,
    &.ui.toggle.checkbox.secondary.disabled,
    &.ui.toggle.checkbox.deactivate {
        input:focus ~ label,
        input:checked:focus ~ label {
            &::before {
                background-color: @disabledColor !important;
            }

            &:hover::before {
                background-color: @disabledColor !important;
            }
        }

        input ~ label,
        input:checked ~ label {
            &::before {
                background-color: @disabledColor !important;
            }

            &:hover::before {
                background-color: @disabledColor !important;
            }
        }

        input ~ label {
            opacity: 0.5;
        }
    }

    // toggle mode styling
    &.ui.toggle.checkbox.secondary {
        input:checked:focus ~ label {
            &::before {
                background-color: @actionColor !important;
            }

            &:hover::before {
                background-color: @hoverActionColor !important;
            }
        }

        input:checked ~ label {
            &::before {
                background-color: @actionColor !important;
            }

            &:hover::before {
                background-color: @hoverActionColor !important;
            }
        }
    }

    &.ui.toggle.checkbox {
        input ~ label {
            &::before {
                background-color: #fff !important;
                border: 1px solid #eee;
                padding: 12px;
            }

            &:hover::before {
                background-color: #f9f9f9 !important;
            }

            &::after {
                margin-top: 3px;
                margin-left: 4px;
                width: 20px;
                height: 20px;
            }
        }

        input:checked:focus ~ label {
            &::before {
                background-color: rgb(21, 146, 79) !important;
                border: 1px solid #eee;
                padding: 12px;
            }

            &:hover::before {
                background-color: rgb(21, 146, 79) !important;
            }
        }

        input:checked ~ label {
            &::before {
                background-color: #18b862 !important;
            }

            &:hover::before {
                background-color: rgb(21, 146, 79) !important;
            }

            &::after {
                margin-left: -4px;
                width: 20px;
                height: 20px;
                border: none;
            }
        }
    }

    // radio mode styling
    &.ui.radio.checkbox {
        input ~ label {
            &::before {
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                border-color: #979797;
            }

            &:hover::before {
                border: #777 1.5px solid;
            }

            &::after {
                display: none;
            }
        }

        input:checked ~ label {
            &::before {
                width: 16px;
                height: 16px;
                border: solid 4px #99969b;
            }

            &:hover::before {
                border: #777 4.5px solid;
            }

            &::after {
                display: none;
            }
        }
    }
}
