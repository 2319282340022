@import '/src/client/theme/colors.less';
@import '/src/client/theme/consts.less';
@import '../../sharedStyles.less';

:local(.listingsContainer) {
    max-height: 650px;
    overflow-y: auto;
    position: relative;
    @media @mobile {
        max-height: initial;
        overflow-x: initial;
        overflow-y: initial;
    }
}

:local(.ticketType) {
    display: flex;
    align-items: center;
    font-size: @h5FontSize;
    font-weight: bold;

    :local(.icon) {
        margin-right: 10px;
    }
}

:local(.quantity) {
    font-weight: bold;
}

:local(.icon) {
    max-width: 40px !important;
}

:local(.mainColumn) {
    display: flex;
    align-items: center;

    img {
        margin-top: -4px;
    }
}

:local(.section) {
    flex-grow: 1;
    font-size: @h4FontSize;
    font-weight: bold;
}

:local(.sectionBlock) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        flex-shrink: 0;
    }
    @media @mobile, @mobileLandscape {
        :local(.infoSection) {
            display: none;
        }
    }
}

:local(.buttonSection) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    button {
        height: 60px;
    }
}

:local(.reserved) {
    color: @dangerColor;
    font-size: @smallFontSize;
    font-weight: bold;
    margin-top: @baseline/4;
}

:local(.listingsHeader) {
    display: flex;
}

:local(.listingRow),
:local(.sortingRow) {
    display: grid;
    grid-gap: @baseline/2 + @baseline/4;
    padding: @baseline;
    border: 1px solid @tableGreyBolderColor;
    border-top: none;
}

:local(.listingRow) {
    min-height: 120px;
    grid-template-columns: @seatingPlanTableDimensionSize auto 150px;
    @media @mobile, @mobileLandscape {
        grid-template-columns: @seatingPlanTableDimensionSizeMobile auto;
        grid-template-rows: 1fr min-content;

        & > div:first-child {
            grid-row: e("1 / 3");
        }
    }
}

:local(.sortingRow) {
    grid-template-columns: @seatingPlanTableDimensionSize auto;
    position: sticky;
    background-color: @txWhite;
    z-index: 1;
    @media @mobile, @mobileLandscape {
        grid-template-columns: @seatingPlanTableDimensionSize auto;
        grid-template-columns: @seatingPlanTableDimensionSizeMobile auto;
        padding: @baseline/2 @baseline;

        &:local(.buyOnly) {
            top: 158px;
        }
    }
    @media @mobile {
        top: 270px;
        padding: @baseline/2;
    }
    @media @mediumMobile {
        top: 295px;
    }
    @media (max-width: 354px) {
        top: 320px;
    }

    :local(.sortHeader) {
        color: @gentleGreyColor;
        font-weight: @defaultFontWeight;
        padding: @baseline/4 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        & svg {
            visibility: hidden;
            fill: @gentleGreyColor;
            margin: 0 @txBlack/4;
        }

        :local(.visibleIcon) {
            visibility: visible;
        }

        &:hover {
            color: @txBlack;

            & svg {
                fill: @txBlack;
            }
        }
        @media @mobile, @mobileLandscape, @tablet {
            font-size: @smallFontSize;
        }
    }

    & > div:last-child {
        justify-self: end;
    }
}

:local(.infoSection) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
        margin: @baseline/4;
    }
}
