@import '/src/client/theme/consts.less';
@import '/src/client/theme/colors.less';

:local(.simplifiedContainer) {
    padding: @baseline @baseline @baseline*2 @baseline;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: @baseline*2;
}

:local(.eventsContainer) {
    padding-top: @baseline;
}

:local(.advancedContainer) {
    display: grid;
    grid-gap: @baseline*2 @layoutUnit;
    grid-template-columns: calc(100% - 330px - @layoutUnit) 330px;
    grid-template-rows: repeat(4, auto);
    padding: @baseline @baseline @baseline*2 @baseline;

    & > * {
        grid-column: e("1 / 2");
    }
}

:local(.secondarySidebar) {
    grid-column: e("2 / 3");
    grid-row: e("1 / -1");
}

:local(.networkSection) {
    padding: @baseline*2 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: @wideContainerWidth) {
    :local(.advancedContainer) {
        grid-template-columns: 100% 330px;
        grid-gap: @baseline*2;
        padding-right: @baseline;
    }

    :local(.secondarySidebar) {
        grid-column: e("1 / 2");
        grid-row: e("1 / 3");
    }

    :local(.performerSidebar) {
        grid-row: e("2 / 3");
    }

    :local(.networkSection) {
        padding: @baseline * 2 0;
    }
}
