// stylelint-disable unit-no-unknown
@import '/src/client/theme/consts.less';
@import '/src/client/theme/colors.less';

@lightFont: #fff;
@darkFont : #4a4a4a;
@color0    : #ef5350;
@fontColor0: @lightFont;
@color1    : #4cd263;
@fontColor1: @lightFont;
@color2    : #2e9cf4;
@fontColor2: @lightFont;
@color3    : #ff9500;
@fontColor3: @darkFont;
@color4    : #ff7874;
@fontColor4: @darkFont;
@color5    : #f7cd46;
@fontColor5: @darkFont;
@color6    : #604ef1;
@fontColor6: @darkFont;
@color7    : #757575;
@fontColor7: @lightFont;
@color8    : #ffd7ac;
@fontColor8: @darkFont;
@color9    : #ff00ff;
@fontColor9: @lightFont;

@filterColor: #e6dcd3;
@filterFontColor: @darkFont;

:local(.venueMap) {
    // height: 100%;
    width: 100%;
    position: relative;

    // override .dimmer styles
    .ui.loader {
        &.ui.ui::after {
            border-color: #767676 transparent transparent;
        }
    }

    :local(.filtersSectionWrapper) {
        display: flex;
        justify-content: space-between;
        border: 1px solid #e5e5e5;
        border-bottom: none;
        border-radius: @standardBorderRadius @standardBorderRadius 0 0;
    }

    :local(.seatingPlanContainer) {
        border: 1px solid #e5e5e5;
        border-top: none;
        border-radius: 0 0 @standardBorderRadius @standardBorderRadius;
        // https://gitlab.com/ticketex/ticketex/-/issues/3025
        // stylelint-disable-next-line property-no-unknown
        aspect-ratio: 1/1;
        width: 100%;
        position: relative;
        @media @notLarge {
            border: none;
        }
        @media @tablet {
            height: calc(50vh - 130px);
        }
        @media @mobile {
            height: calc(100dvh - 150px);
        }
    }

    :local(.seatingPlanContainerIOS) {
        @media @mobile {
            height: calc(100dvh - 178px);
        }
    }

    :local(.loaderContainer) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    :local(.seatingPlan) {
        svg {
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    .categories {
        display: flex;
        flex-flow: row;
        padding: @baseline/2;
        margin: 0;
        flex-wrap: wrap;

        .ui.checkbox {
            label::before {
                opacity: 0.5;
                border-radius: 2px;
            }

            input:checked ~ label::before {
                opacity: 1;
            }
        }
    }

    .categoryLabel {
        opacity: 0.5;
        font-size: 12px;
        margin: 10px;
        font-weight: bold;
        letter-spacing: 0.2px;
        color: @primaryTextColor;
    }

    .categoryReset {
        position: absolute;
        top: 21px;
        left: 10px;
        font-size: 13px;
        font-weight: bold;
        text-decoration: underline;
        letter-spacing: 0.3px;
        color: #a3a0a5;
        text-transform: none;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;

        &:hover {
            color: lighten(#a3a0a5, 15%);
        }

        &:disabled {
            opacity: 0.7;
            text-decoration: none;
        }
        @media @large {
            display: none;
        }
    }

    .clearAll {
        font-weight: bold;
        letter-spacing: 0.3px;
        color: #a3a0a5;
        text-transform: none;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;

        &:hover {
            color: lighten(#a3a0a5, 15%);
        }

        &:disabled {
            opacity: 0.7;
            text-decoration: none;
        }
    }

    :local(.zoom) {
        display: flex;
        flex-direction: column;
        min-width: 60px;
        height: 90px;
        justify-content: flex-end;
        align-items: flex-end;
        gap: @baseline/2;
        margin: @baseline;

        button {
            margin-bottom: auto;
        }
    }

    * {
        transition: fill 0.2s ease, stroke-width 0.2s ease, stroke 0.2s ease;
    }

    .invisible,
    .invisible * {
        display: none;
    }

    .inactive:not(text),
    .inactive > *:not(text) {
        animation-name: make-transparent;
        // ACHTUNG! this may affect e2e tests, so please edit `wait` in e2e-tests/src/eventDetails/index.ts if you change animation duration/delay!
        animation-duration: 1s;
        animation-delay: 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .colors(@i) when (@i < 10) {
        @colorName    : "color@{i}";
        @fontColorName: "fontColor@{i}";

        .color@{i},
        .color@{i} > *:not(text) {
            fill: @@colorName !important;
        }

        .color@{i}-semi-opaque,
        .color@{i}-semi-opaque > *:not(text) {
            fill: fade(@@colorName, 30%) !important;
        }

        .color@{i}-outlined,
        .color@{i}-outlined > *:not(text) {
            fill: @@colorName !important;
            stroke: #000 !important;
            stroke-width: 0.4px !important;
        }

        .category@{i} {
            background: @@colorName !important;
            border-radius: 2px;
            padding: 4px;
            padding-right: 14px;
            margin: 3px;

            label {
                font-weight: bold;
                color: @@fontColorName;

                &:hover {
                    color: @@fontColorName;
                }
            }
        }

        .colors(@i+1);
    }

    .colors(0);

    :local(.filtersSectionWrapperEditListing) {
        border: none;

        .ui.checkbox {
            label::before {
                display: none;
            }
        }

        & > :local(.zoom) {
            margin-top: 0 !important;
        }
    }

    & > :local(.noBorder) {
        border: none;
    }
}

:local(.modalContentEditListingSeatingMap) {
    > :nth-child(2) {
        padding-bottom: 0;
    }
}
@keyframes make-transparent {
    from {
        fill-opacity: 1;
    }

    to {
        fill-opacity: 0;
    }
}

:local(.filterBtn) {
    background: white;
    border: 1px solid #ddd;
    padding: @baseline;
    width: 100%;
    font-size: @defaultFontSize;
    @media @mobile, @mobileLandscape {
        padding: @baseline/2 @baseline;
        margin-bottom: @baseline/2;
    }
    @media @mobile {
        margin-top: @baseline/2;
        margin-bottom: 1.5*@baseline;
    }
}

:local(.ticketTypeFilter) {
    background: @filterColor;
    border-radius: 2px;
    padding: 4px;
    padding-right: 14px;
    margin: 3px;

    label {
        font-weight: bold;
        color: @filterFontColor;

        &:hover {
            color: @filterFontColor;
        }
    }
}

:local(.filtersSection) {
    @media @notLarge {
        margin: 0 @baseline;

        .ui.checkbox input:checked ~ label::after {
            color: #fff !important;
            background-color: #000;
        }

        .categories {
            display: flex;
            flex-direction: column;
            gap: 1.5*@baseline;
            margin-bottom: 1.5*@baseline;

            .ui.checkbox {
                font-size: @defaultFontSize;
            }
        }

        :local(.ticketTypeFilter) {
            background: none;
            margin: 0;
            padding: 0;

            label {
                font-weight: normal;
            }
        }

        :local(.filtersTitle) {
            margin-bottom: 2*@baseline;
            font-size: @h3FontSize;
            line-height: 32px;
            font-weight: @defaultFontWeight;
        }

        :local(.greyLine) {
            height: 1px;
            width: 100%;
            background-color: @tableGreyBolderColor;
            margin: 2*@baseline 0;
        }

        .categoryLabel {
            font-size: @h5FontSize;
            font-weight: @defaultFontWeight;
        }
    }
}

:local(.seatingPlanContainer) {
    border: 1px solid #e5e5e5;
    border-top: none;
    border-radius: 0 0 @standardBorderRadius @standardBorderRadius;
}
